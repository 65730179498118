<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #e53935">เพิ่มคูปอง</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-8"
            ><span class="pt-/">สถานะ</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-switch v-model="activeFlag" label="ใช้งาน"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ชื่อหัวข้อ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="couponName"
              label="ชื่อคูปอง"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">รหัสใช้คูปอง</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="couponCode"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ประเภทคูปอง</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              v-model="couponType"
              :items="[
                'ลดทั้งบิล',
                'ลดตามหมวดหมู่',
                'ลดตามยี่ห้อ',
                'ลดเฉพาะสินค้า',
                'ลดค่าจัดส่ง',
              ]"
              placeholder="เลือกประเภทคูปอง"
              dense
              solo
              :rules="rules.required"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="couponType === 'ลดตามหมวดหมู่'">
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ลดตามหมวดหมู่</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-autocomplete
              v-model="category"
              :items="itemsCategory"
              item-text="category"
              item-value="id"
              placeholder="เลือกหมวดหมู่สินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="couponType === 'ลดตามยี่ห้อ'">
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ลดตามยี่ห้อ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-autocomplete
              v-model="brand"
              :items="itemsBrand"
              item-text="brandName"
              item-value="id"
              placeholder="เลือกยี่ห้อสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="couponType === 'ลดเฉพาะสินค้า'">
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ลดเฉพาะสินค้า</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-autocomplete
              v-model="product"
              :items="itemsProduct"
              item-text="productName"
              item-value="id"
              placeholder="เลือกสินค้า"
              solo
              dense
              :rules="rules.required"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ประเภทการลด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-select
              v-model="discountType"
              :items="['จำนวนเงิน', 'เปอร์เซ็น']"
              placeholder="เลือกประเภทการลด"
              dense
              solo
              :rules="rules.required"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ราคาส่วนลด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7" v-if="discountType == 'จำนวนเงิน'">
            <v-text-field
              v-model="discountPrice"
              solo
              dense
              :rules="rules.required"
              type="number"
              placeholder="บาท / เปอร์เซ็นต์"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="7" sm="7" v-if="discountType == 'เปอร์เซ็น'">
            <v-text-field
              v-model="discountPrice"
              solo
              dense
              :rules="rules.required"
              type="number"
              placeholder="%"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ขั้นต่ำในการใช้</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="minimumDiscount"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ราคาส่วนลดสูงสุด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="maxDiscount"
              solo
              dense
              label="บาท"
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">จำนวนคูปอง</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="couponAmt"
              solo
              dense
              :rules="rules.required"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3" class="text-left pt-5"
            ><span class="pt-2">ระยะเวลาของคูปอง</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-menu
                  ref="menufrom"
                  v-model="menufrom"
                  :close-on-content-click="false"
                  :return-value.sync="datefrom"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="datefrom"
                      label="วันที่เริ่ม"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      solo
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datefrom"
                    no-title
                    scrollable
                    :min="firstDate"
                  >
                    <v-btn text color="primary" @click="menufrom = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menufrom.save(datefrom)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menuto"
                  v-model="menuto"
                  :close-on-content-click="false"
                  :return-value.sync="dateto"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateto"
                      label="วันที่สิ้นสุด"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      solo
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateto" no-title scrollable>
                    <v-btn text color="primary" @click="menuto = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuto.save(dateto)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col cols="12" md="12" sm="12" xs="12">
                <v-row align="center" justify="center">
                  <v-btn width="80px" class="mt-2 mb-4 mr-4" @click="clear()"
                    >เคลียร์</v-btn
                  >
                  <v-btn
                    width="80px"
                    class="mt-2 mb-4"
                    @click="getForm()"
                    color="#833133"
                    dark
                    >ค้นหา</v-btn
                  >
                </v-row>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      couponType: "",
      discountType: "จำนวนเงิน",
      couponName: "",
      couponCode: "",
      // couponType
      discountPrice: null,
      minimumDiscount: null,
      maxDiscount:null,
      couponAmt: null,
      // couponStart:'',
      // couponEnd:'',
      category: "",
      brand: "",
      product: "",
      company: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      firstDate: new Date("2021-06-01").toISOString().slice(0, 10),
      eppFlag: false,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  created() {
    this.getDropdown();
  },
  methods: {
    async getDropdown() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      const typeDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/types`
      );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      const productDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/products`
      );
      this.itemsCategory = cateDropdown.data.data;
      this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      this.itemsProduct = productDropdown.data.data;
      console.log("itemsCompany", this.itemsCompany);
      //////////get dropdown list/////////////
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageCoupon");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userSPM"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const data = {
          couponName: this.couponName,
          couponCode: this.couponCode,
          couponType: this.couponType,
          discountType: this.discountType,
          discountPrice: this.discountPrice,
          minimumDiscount: this.minimumDiscount,
          maxDiscount: this.maxDiscount,
          couponAmt: this.couponAmt,
          couponStart: this.datefrom,
          couponEnd: this.dateto,
          eppFlag: this.eppFlag,
          categoryId: this.category || null,
          brandId: this.brand || null,
          productId: this.product || null,
          companyId: this.company || null,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/coupons`,
          data,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างคูปองส่วนลดสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageCoupon");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างคูปองส่วนลดไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>